<template>
  <div class="downCon">
    <p class="logo"></p>
    <p class="title">
      <span>{{ download }}</span>
      <span></span>
    </p>
    <p class="agree" @click="openLink">{{ btnText }}</p>
  </div>
</template>
<script>
import L from "../../assets/js/language";
export default {
  name: "Download",
  data() {
    return {};
  },
  methods: {
    openLink() {
      window.location.href = "https://www.order.kfc.com.hk/NewAPP";
    },
  },
  computed: {
    T() {
      return this.$store.state.language == 2 ? "english" : "chinese";
    },
    download() {
      return L.download[this.T];
    },
    btnText() {
      return L.btnText[this.T];
    },
  },
};
</script>
<style scoped>
.downCon {
  width: 100%;
  height: 0.56rem;
  background-color: #03060d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.16rem;
  box-sizing: border-box;
  color: #fff;
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 99;
  transform: translateX(-50%);
}
.logo {
  width: 0.28rem;
  height: 0.28rem;
  background: url("../../assets/icon/logo@2x.png") no-repeat center;
  background-size: cover;
}
.title {
  width: 2.3rem;
  font-size: 0.12rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 18px;
  color: #e4022b;
  text-align: left;
}
.title span:first-child {
  color: #fff;
}
.set {
  font-size: 0.12rem;
  border-bottom: 0.01rem solid #fff;
}
.agree {
  font-size: 0.12rem;
  /* width: 0.52rem; */
  height: 0.32rem;
  line-height: 0.32rem;
  text-align: center;
  background-color: #e4022b;
  border-radius: 0.04rem;
  padding: 0 0.04rem;
}
</style>