<template>
  <div id="main" :class="{ active: isH5 }">
    <div class="download" v-if="isH5">
      <download></download>
    </div>
    <div class="topbar">
      <div></div>
      <div class="title">
        <i></i>
        <i></i>
        <i></i>
      </div>
      <div class="login" @click="login" v-if="!$store.state.userInfo && !isYuu">
        {{ loginAndRegister }}
      </div>
    </div>
    <div class="helloBox"></div>
    <div class="noticeSwiper" v-show="brandInfos && brandInfos.length">
      <notice-swiper :brandInfos="brandInfos"></notice-swiper>
    </div>
    <!-- 轮播图 -->
    <swiper @clickTag="clickTag" :page="1" :imglist="banner"></swiper>
    <div class="buyAlert">
      <p>{{ new_SY_liJiYueYe }}</p>
      <p>{{ new_SY_xuanZeDiZhi }}</p>
    </div>
    <!--  -->
    <div class="bodyInfo">
      <div class="location">
        <p>
          <span></span>
          <span>{{ new_SY_xianZaiWeiZhi }}{{ place }}</span>
        </p>
        <p @click="updatePlace(true)"><i></i> {{ new_SY_gengGai }}</p>
        <p class="gps" v-show="showGps">請開啟網絡進行定位服務。</p>
      </div>
      <!-- <div class="">
        <notice-swiper :brandInfos="brandInfos"></notice-swiper>
      </div> -->
      <!-- 点餐 -->
      <div class="order">
        <div @click="changePage('orderFood/1')">
          <img src="@/assets/icon/delivery.png" alt="" />
          <p :style="{ marginBottom: rem + 'rem' }">
            <span>{{ new_SY_haoWeiSuDi }}</span>
            <i></i>
          </p>
        </div>
        <div @click="changePage('selectShop', true)">
          <img src="@/assets/icon/pickup.png" alt="" />
          <p :style="{ marginBottom: rem + 'rem' }">
            <span>{{ new_SY_tangShiZiQu }}</span>
            <i></i>
          </p>
        </div>
      </div>
    </div>
    <!-- 底部輪波圖 -->
    <div class="bottomBanner" v-if="bottomBanner.length">
      <div
        class="cover"
        v-for="(item, index) in bottomBanner"
        :key="index"
        @click="clickBotBan(index)"
      >
        <img :src="item.picUrl" alt="" />
      </div>
    </div>
    <!-- 送餐进度小车 -->
    <div class="deli" v-show="showProgress" @click="selectPickFunc(true)">
      <img src="@/assets/icon/delivery_default.png" alt="" />
      <p>
        <span>{{ orderProgress }}</span>
      </p>
      <img src="@/assets/icon/play.png" alt="" />
    </div>
    <myplace
      v-show="showPlace"
      @update="updatePlace"
      @select="selectPlce"
      @closeMyPlace="closeMyPlace"
    ></myplace>
    <advert
      @closeAdvert="closeAdvert"
      v-if="showAdvert"
      :advert="advert"
    ></advert>
    <pick-order
      v-show="showPickFunc"
      @pickOrder="changePageFood"
      :showPickFunc="showPickFunc"
      @selectPickFunc="selectPick"
    ></pick-order>
  </div>
</template>

<script>
import Swiper from "../components/swiper/Swiper.vue";
import NoticeSwiper from "../components/swiper/NoticeSwiper.vue";
import Myplace from "../components/myplace/Myplace.vue";
import Advert from "../components/advert/Advert.vue";
import Download from "../components/common/Download.vue";
import PickOrder from "../components/pickOrder/PickOrder.vue";
import language from "../assets/js/language.js";
import { postDC, postPaas } from "../assets/utils/request";
import { mapState } from "vuex";

export default {
  name: "Home",
  data() {
    return {
      imgList: [1, 2],
      showGps: false,
      showPlace: false,
      showAdvert: false,
      showPickFunc: false,
      showProgress: false,
      canShowList: [],
      rem: 0,
      pickType: 0, // 1 从购物车进  2 从banner进
      bannerPath: null, //点击banner的跳转地址
      bannerPathInfo: null,
      time: "",
      timeText: "",
      waiMaiSuda: "",
      start: "",
      WaiMaiZiQu: "",
      orderProgress: "",
      yuJiSongDa: "",
      change: "",
      loginAndRegister: "",
      placeInfo: null, //选择的地址信息
      margin: false,
      banner: [],
      bottomBanner: [],
      brandInfos: [],
      advert: null,
      new_SY_liJiYueYe: "",
      new_SY_xuanZeDiZhi: "",
      new_SY_haoWeiSuDi: "",
      new_SY_xianZaiWeiZhi: "",
      new_SY_tangShiZiQu: "",
      new_SY_gengGai: "",
    };
  },
  methods: {
    async getH5Info() {
      var params = {
        actionName: "candao.storeOwn.getH5Setting",
        content: {},
      };
      let result = await postDC("Action", params);
      if (result.status == 1) {
        this.banner = result.data.topBannerList;
        this.bottomBanner = result.data.bottomBannerList;
        if (result.data.brandInfos && result.data.brandInfos.length) {
          this.brandInfos = [];
          for (var i = 0; i < result.data.brandInfos.length; i++) {
            if (
              result.data.brandInfos[i].announcement ||
              result.data.brandInfos[i].announcementEn
            ) {
              this.brandInfos.push(result.data.brandInfos[i]);
            }
          }
        }
        if (result.data.advertisingList && result.data.advertisingList.length) {
          var advertUrl =
            localStorage.getItem("KFCAdvert") ||
            sessionStorage.getItem("KFCAdvert");
          if (advertUrl != result.data.advertisingList[0].jumpUrl + "KFC") {
            this.advert = result.data.advertisingList[0];
            if (this.$store.state.userInfo) {
              localStorage.setItem("KFCAdvert", this.advert.jumpUrl + "KFC");
            } else {
              sessionStorage.setItem("KFCAdvert", this.advert.jumpUrl + "KFC");
            }
            this.showAdvert = true;
          }
        }
        if (this.banner && this.banner.length) {
          this.margin = true;
        }
      }
    },
    closeAdvert() {
      this.showAdvert = false;
    },
    updatePlace(type) {
      this.showPlace = type;
    },
    closeMyPlace() {
      this.showPlace = false;
    },
    // 选择完的地址
    selectPlce(info) {
      this.placeInfo = info;
      this.showPlace = !this.showPlace;
      this.$store.commit("savePlace", info);
      // this.dataLayerLocalise();
    },
    selectPickFunc() {
      if (this.canShowList.length == 1) {
        this.$router.push("/orderDetail/" + this.canShowList[0].recordId);
      } else {
        this.$router.push("/orderList");
      }
    },
    login() {
      this.$router.push("/login");
      this.dataLayerPush()
    },
    // 埋点
    dataLayerPush() {
      window.dataLayer.push({
        event: "hp_reg_login",
      });
    },
    dataLayerLocalise(bool) {
      let place = this.$store.state.placeInfo;
      let ecommerce = {
        Service: !bool ? "delivery" : "dine in",
        // fulfillment_time,
        user_area: "HK",
        Allow_GPS: true,
      };
      if (place) {
        ecommerce.user_longlat = [place.lng, place.lat];
      }
      window.dataLayer.push({
        event: "localise",
        ecommerce,
      });
    },
    changePage(path, bool, info) {
      // this.dataLayerLocalise(bool);
      if (bool) {
        let B = 3;
        if (info && info.businessType) {
          B = info.businessType;
        }
        if (info) {
          info = JSON.stringify(info);
        }
        this.$router.push({
          path: "/" + path,
          query: {
            type: B,
            bannerInfo: info,
          },
        });
      } else {
        if (this.$store.state.placeInfo) {
          this.$router.push("/" + path);
        } else {
          this.$router.push({
            path: "/selectShop",
            query: {
              type: 1,
            },
          });
        }
      }
    },
    changePageFoodCart() {
      this.changePageFood();
      this.pickType = 1;
    },
    //
    changePageFood() {
      this.showPickFunc = !this.showPickFunc;
      if (this.showPickFunc) {
        this.$store.commit("updateTabbarShow", false);
      } else {
        this.$store.commit("updateTabbarShow", true);
      }
    },
    //
    selectPick(type) {
      if (this.pickType == 1) {
        var path;
        var bool = false;
        if (type == 1) {
          path = "orderFood/1";
        } else {
          path = "selectShop";
          bool = true;
        }
        this.changePage(path, bool);
        return;
      }
      if (this.pickType == 2) {
        var param = this.bannerPathInfo;
        if (type == 1) {
          param.businessType = 1;
        } else {
          param.businessType = 3;
        }
        if (this.bannerPath == "selectShop") {
          this.changePage(this.bannerPath, true, this.bannerPathInfo);
          return;
        }
        param = JSON.stringify(param);
        param = encodeURIComponent(param);
        this.changePage(this.bannerPath + param);
      }
    },
    // 获取订单列表
    async getOrderList() {
      var params = {
        actionName: "candao.member.orderList",
        content: {},
      };
      if (this.$store.state.language == 1) {
        params.content.language = "TC";
      }
      let result = await postPaas("UserUnify", params);
      if (result.status == 1) {
        var list = result.data.data;
        this.canShowList = [];
        for (var l = 0; l < list.length; l++) {
          if (this.canShowList.length > 1) break;
          if (
            list[l].orderStatusCode != "S006" &&
            list[l].orderStatusCode != "S007" &&
            list[l].orderStatusCode != "S008"
          ) {
            this.canShowList.push(list[l]);
          }
        }
        if (this.canShowList.length != 0) {
          this.showProgress = true;
        }
      }
    },
    // 點擊底部輪播圖
    clickBotBan(index) {
      var param = {
        index,
        page: 10,
      };
      this.clickTag(param);
    },
    // 点击轮播图回调
    clickTag(param) {
      var info;
      var index = param.index;
      if (param.page == 1) {
        info = this.banner[index];
      } else if (param.page == 10) {
        info = this.bottomBanner[index];
      }
      if (info.jumpType == 1) {
        window.location.href = info.jumpUrl;
        return;
      }
      if (info.jumpType == 2) {
        var paramOne = {
          page: 1,
          jumpType: 2,
          businessType: info.businessType,
          firstLevel: info.productPrimaryTypeName,
        };
        if (info.productSecondaryTypeName) {
          paramOne.secondLevel = info.productSecondaryTypeName;
        }
        if (info.storeId) {
          paramOne.storeId = info.storeId;
        }

        if (info.businessType != 0) {
          if (info.businessType == 1 || info.businessType == 2) {
            paramOne = JSON.stringify(paramOne);
            paramOne = encodeURIComponent(paramOne);
            this.changePage("orderFood/" + paramOne);
            return;
          }
          if (info.storeId) {
            paramOne = JSON.stringify(paramOne);
            paramOne = encodeURIComponent(paramOne);
            this.changePage("orderFood/" + paramOne);
            return;
          }
          this.changePage("selectShop", true, paramOne);
          return;
        }
        this.pickType = 2;
        this.bannerPathInfo = paramOne;
        if (info.storeId) {
          this.bannerPath = "orderFood/";
        } else {
          if (this.$store.state.placeInfo) {
            this.bannerPath = "orderFood/";
          } else {
            this.bannerPath = "selectShop";
            paramOne.businessType = 3;
          }
        }
        this.changePageFood();
        return;
      }
      if (info.jumpType == 6) {
        var paramTwo = {
          page: 1,
          jumpType: 6,
          businessType: info.businessType,
          productId: info.specifiedProductId,
          productName: info.specifiedProductName,
        };
        if (info.storeId) {
          paramTwo.storeId = info.storeId;
        }
        if (info.businessType != 0) {
          if (info.businessType == 1 || info.businessType == 2) {
            if (info.businessType == 2) {
              this.changePage("selectShop", true, paramTwo);
              return;
            }
            paramTwo = JSON.stringify(paramTwo);
            paramTwo = encodeURIComponent(paramTwo);
            this.changePage("orderFood/" + paramTwo);
            return;
          }
          if (info.storeId) {
            paramTwo = JSON.stringify(paramTwo);
            paramTwo = encodeURIComponent(paramTwo);
            this.changePage("orderFood/" + paramTwo);
            return;
          }
          this.changePage("selectShop", true, paramTwo);
          return;
        }
        this.pickType = 2;
        this.bannerPathInfo = paramTwo;
        if (info.storeId) {
          this.bannerPath = "orderFood/";
        } else {
          this.bannerPath = "selectShop";
        }
        this.changePageFood();
      }
    },
    //设置语言
    setlanguage() {
      var languageType;
      var nowTime = new Date().getTime(); //当前时间
      var year = new Date().getFullYear();
      var month = new Date().getMonth() + 1;
      var day = new Date().getDate();
      var date = year + "/" + month + "/" + day + " ";
      var breakfast = new Date(date + "11:29").getTime();
      var lunch = new Date(date + "14:29").getTime();
      var tea = new Date(date + "17:29").getTime();
      if (this.$store.state.language == 1) {
        languageType = "chinese";
      } else {
        languageType = "english";
        this.rem = 0.2;
      }
      var timeType;
      var timeTextType;
      var saveTimeType;
      if (nowTime < breakfast) {
        timeType = "morning";
        timeTextType = "breakfast";
        saveTimeType = "H01";
      } else if (nowTime < lunch) {
        timeType = "lunch";
        timeTextType = "lunchText";
        saveTimeType = "H02";
      } else if (nowTime < tea) {
        timeType = "tea";
        timeTextType = "teaText";
        saveTimeType = "H03";
      } else {
        timeType = "dinner";
        timeTextType = "dinnerText";
        saveTimeType = "H04";
      }
      this.$store.commit("saveTime", saveTimeType);
      this.time = language[timeType][languageType];
      this.timeText = language[timeTextType][languageType];
      this.waiMaiSuda = language.waiMaiSuDa[languageType];
      this.start = language.start[languageType];
      this.WaiMaiZiQu = language.WaiMaiZiQu[languageType];
      this.orderProgress = language.orderProgress[languageType];
      this.yuJiSongDa = language.yuJiSongDa[languageType];
      this.change = language.change[languageType];
      this.loginAndRegister = language.loginAndRegister[languageType];
      this.new_SY_liJiYueYe = language.new_SY_liJiYueYe[languageType];
      this.new_SY_xuanZeDiZhi = language.new_SY_xuanZeDiZhi[languageType];
      this.new_SY_haoWeiSuDi = language.new_SY_haoWeiSuDi[languageType];
      this.new_SY_xianZaiWeiZhi = language.new_SY_xianZaiWeiZhi[languageType];
      this.new_SY_tangShiZiQu = language.new_SY_tangShiZiQu[languageType];
      this.new_SY_gengGai = language.new_SY_gengGai[languageType];
    },
  },
  created() {
    this.$store.commit("saveJumpDetail", true);
    window.dataLayer.push({
      event: "welcome",
    });
    this.$store.commit("updateTabbarShow", true);
    this.setlanguage();
    this.getH5Info();
    // this.getOrderList();
  },
  computed: {
    ...mapState(["isYuu", "app"]),
    isH5() {
      let u = navigator.userAgent;
      let isPC = true;
      if (u.indexOf("iPhone") > -1 || u.indexOf("iphone") > -1) {
        isPC = false;
      } else if (u.indexOf("Android") > -1 || u.indexOf("android") > -1) {
        isPC = false;
      }
      return !this.isYuu && !this.app && !isPC ? true : false;
    },
    place() {
      var build =
        this.$store.state.language == 1 ? "無法定位" : "Location Not Available";
      if (this.$store.state.placeInfo) {
        build = this.$store.state.placeInfo.building;
      }
      return build;
    },
    // 购物车数量
    cartLength() {
      var cart = this.$store.state.cart;
      var length = 0;
      for (var i = 0; i < cart.length; i++) {
        length += Number(cart[i].num);
      }
      return length;
    },
  },
  components: {
    Swiper,
    Myplace,
    Advert,
    NoticeSwiper,
    Download,
    PickOrder,
  },
};
</script>

<style scoped lang="scss">
#main {
  padding-bottom: 0.8rem;
}

#main.active {
  padding-top: 0.56rem;
}

.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 0.48rem;
  position: relative;
  padding: 0 0.16rem;
  box-sizing: border-box;
}
.languange {
  width: 0.84rem;
  height: 0.34rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.12rem;
  border-radius: 0.17rem;
  background-color: #e4022b;
}
.languange span {
  width: 45%;
  height: 0.26rem;
  line-height: 0.26rem;
  text-align: center;
  border-radius: 0.15rem;
  margin: 0 0.04rem;
  font-weight: 600;
  color: #fff;
}
.languange span.active {
  background-color: #fff;
  color: #e4022b;
}
.topbar .title {
  position: absolute;
  bottom: 0.23rem;
  left: 0;
  width: 100%;
  height: 0.21rem;
  text-align: center;
  display: flex;
  justify-content: center;
}
.topbar .title i {
  // display: inline-block;
  width: 0.08rem;
  height: 0.1rem;
  background-color: #e4022b;
}
.topbar .title i + i {
  margin-left: 0.1rem;
}
.topbar .login {
  overflow: hidden;
  position: relative;
  padding: 0.04rem 0.15rem;
  font-size: 0.14rem;
  color: #fff;
  border-radius: 0.17rem;
  background: linear-gradient(90deg, #fe1112 0%, #e4022b 100%);
}
.topbar .login img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
}
.helloBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.16rem;
  box-sizing: border-box;
}
.hello {
  width: 100%;
  padding: 0.06rem 0.16rem;
  box-sizing: border-box;
}
.hello p {
  height: 0.25rem;
  font-size: 0.18rem;
  font-family: PingFang HK;
  font-weight: 500;
  line-height: 0.25rem;
  color: #030f14;
  text-align: left;
}
.hello p:nth-child(2) {
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.25rem;
  color: #030f14;
}
.cart {
  width: 0.22rem;
  height: 0.24rem;
  background: url("../assets/icon/homeCart.png") no-repeat center;
  background-size: cover;
  position: relative;
}
.cart span {
  display: inline-block;
  width: 0.12rem;
  height: 0.12rem;
  background-color: #f0a10d;
  border-radius: 50%;
  text-align: center;
  line-height: 0.12rem;
  font-size: 0.1rem;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(50%);
}
.bodyInfo {
  width: 3.43rem;
  // background-color: rgba(255, 255, 255, 0.5);
  background-color: #fff1e1;
  border-radius: 0.12rem;
  margin: 0 0.16rem;
}
.bodyInfo.active {
  margin-top: -0.7rem;
}
.location {
  width: 100%;
  height: auto;
  padding-top: 0.2rem;
  // background: url("../assets/icon/loacBg.png") no-repeat center;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.location p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.location p span:first-child {
  width: 0.25rem;
  height: 0.31rem;
  background: url("../assets/icon/location.png") no-repeat center;
  background-size: cover;
  flex-shrink: 0;
}
.location p span:nth-child(2) {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  text-align: left;
  margin-left: 0.07rem;
  font-weight: 600 !important;
}
.location p {
  height: 0.22rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 500;
  line-height: 0.22rem;
  color: #000;
}
.location p:first-child {
  margin-left: 0.16rem;
  width: 80%;
}
.location p:nth-child(2) {
  color: #808080;
  margin-right: 0.16rem;
  flex-shrink: 0;
  font-size: 0.11rem;
  display: flex;
  align-items: center;
}

.location p:nth-child(2) i {
  width: 0.16rem;
  height: 0.16rem;
  background: url("../assets/icon/vector.png") no-repeat center;
  background-size: cover;
}

.order {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.08rem 0.1rem;
  box-sizing: border-box;
}
.order > div {
  width: 1.68rem;
  height: 1.84rem;
  background: url("../assets/icon/bg.png") no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.order > div img {
  width: 1rem;
  height: 1rem;
}
.order div p {
  width: 100%;
  text-align: center;
  // height: 0.22rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  // line-height: 0.22rem;
  color: #03060d;
  display: flex;
  align-items: center;
  justify-content: center;
}
.order div p span {
  width: 0.9rem;
}

.order div i {
  width: 0.14rem;
  height: 0.14rem;
  display: inline-block;
  background: url("../assets/icon/play.png") no-repeat center;
  background-size: cover;
  margin-left: 0.04rem;
}
.deli {
  height: 0.47rem;
  background: linear-gradient(90deg, #ffffff 0%, #f0bfc9 100%);
  border-radius: 0.25rem;
  position: fixed;
  bottom: 1rem;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.deli img:first-child {
  width: 0.28rem;
  height: 0.28rem;
  object-fit: cover;
  margin-left: 0.16rem;
}
.deli p {
  font-size: 0.12rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.17rem;
  color: #e4022b;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  margin: 0 0.1rem;
}
.deli p span:last-child {
  font-size: 0.1rem;
  font-family: PingFang HK;
  font-weight: 500;
  color: #e4022b;
  opacity: 0.8;
  white-space: nowrap;
}
.deli img:last-child {
  width: 0.12rem;
  height: 0.12rem;
  object-fit: cover;
  margin-right: 0.12rem;
}
.gps {
  width: 2rem;
  height: 0.3rem;
  background: rgba(228, 2, 43, 0.1);
  border: 1px solid #e4022b;
  font-size: 0.12rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.3rem;
  color: #e4022b !important;
  padding: 0 0.07rem;
  box-sizing: border-box;
  white-space: nowrap;
  position: absolute;
  top: 0.5rem;
  left: 0.45rem;
}
.gps::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-left: 1px solid #e4022b;
  border-top: 1px solid #e4022b;
  position: absolute;
  top: -0.06rem;
  left: 0.3rem;
  transform: rotate(45deg);
}
.noticeSwiper {
  margin-bottom: 0.14rem;
}
.bottomBanner {
  // height: 2rem;
  margin: 0.32rem 0.16rem 0;
  border-radius: 0.12rem;
  overflow: hidden;
}

.bottomBanner .cover {
  width: 3.43rem;
  height: 1.83rem;
  overflow: hidden;
  border-radius: 0.12rem;
  margin-bottom: 0.16rem;
}

.bottomBanner .cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.buyAlert {
  font-family: PingFang HK;
  font-style: normal;
  font-weight: 600;
  font-size: 0.2rem;
  line-height: 0.28rem;
  padding: 0 0.16rem;
  box-sizing: border-box;
  margin-bottom: 0.04rem;
  margin-top: 0.14rem;
}
.buyAlert p {
  text-align: left;
}
.buyAlert p:nth-child(2) {
  font-family: PingFang HK;
  font-style: normal;
  font-weight: normal;
  font-size: 0.12rem;
  line-height: 0.17rem;
  color: #808080;
}
</style>
