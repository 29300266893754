<template>
  <div class="placeLists">
    <div class="listItem" @click="editPlace">
      <div class="placeLeft">
        <div class="placeTag" v-if="placeInfo.tagId || placeInfo.isDefault">
          <p
            v-if="placeInfo.tagId"
            :class="{
              home: placeInfo.tagId == 1,
              company: placeInfo.tagId == 2,
              other: placeInfo.tagId == 11,
              school: placeInfo.tagId == 4,
            }"
          >
            <i></i>
            <span>{{ addresTag[placeInfo.tagId] }}</span>
          </p>
          <p v-if="placeInfo.isDefault" class="defaultIcon">
            <i></i>
            <span>{{ $store.state.language == 1 ? "預設" : "Default" }} </span>
          </p>
        </div>
        <p>
          <span>{{ placeInfo.building }}</span>
        </p>
        <p>{{ placeInfo.district }}</p>
        <!-- <p>
          <span
            >{{ placeInfo.contactPerson
            }}{{ titleTag[placeInfo.titlesId] }}</span
          >
          <span>{{ placeInfo.phoneNo }}</span>
        </p> -->
      </div>
      <div class="editIcon" v-if="edit"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PlaceItem",
  data() {
    return {};
  },
  props: {
    // showIcon: {
    //   type: Number,
    //   default: 0,
    // },
    edit: {
      type: Boolean,
      default: true,
    },
    placeInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    titleTag() {
      var arr = this.$store.state.userTitle;
      var titleObj = {};
      for (var i = 0; i < arr.length; i++) {
        titleObj[arr[i].titlesId] = arr[i].titlesName;
      }
      return titleObj;
    },
    addresTag() {
      var arr = this.$store.state.addressTag;
      var titleObj = {};
      for (var i = 0; i < arr.length; i++) {
        titleObj[arr[i].tagId] = arr[i].tagName;
      }
      return titleObj;
    },
  },
  methods: {
    editPlace() {
      if (!this.edit) return;
      var info = JSON.stringify(this.placeInfo);
      info = encodeURIComponent(info);
      this.$router.push({
        path: "/sendPlace",
        query: { place: info },
      });
    },
  },
};
</script>
<style scoped>
.placeLists {
  width: 100%;
  padding: 0.16rem 0;
  box-sizing: border-box;
  border-bottom: 1px solid #cacbcc;
}
.listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.placeLeft {
  width: 93%;
}
.placeLeft p {
  width: 100%;
  height: 0.22rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.22rem;
  color: #030f14;
  text-align: left;
}
.placeLeft p + p {
  width: 100%;
  height: 0.17rem;
  font-size: 0.1rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.17rem;
  color: #b1b1b3;
  /* margin-top: 0.04rem; */
}
.editIcon {
  width: 0.08rem;
  height: 0.14rem;
  background: url("../../assets/icon/rightRnow.png") no-repeat center;
  background-size: cover;
}
.placeLeft p:last-child span + span {
  margin-left: 0.04rem;
}
.placeLeft p:first-child {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.placeLeft p:first-child span:nth-child(1) {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.placeLeft p:first-child span:nth-child(2) {
  display: inline-block;
  /* width: 0.3rem; */
  padding: 0 0.04rem;
  height: 0.14rem;
  margin-left: 0.08rem;
  font-size: 0.1rem;
  text-align: center;
  line-height: 0.16rem;
  border-radius: 0.02rem;
}
.placeLeft p:first-child span:nth-child(2).homeActive {
  border: 0.01rem solid #feb529;
  color: #feb529;
}
.placeLeft p:first-child span:nth-child(2).companyActive {
  border: 0.01rem solid #3089ba;
  color: #3089ba;
}
.placeLeft p:first-child span:nth-child(2).otherActive {
  border: 0.01rem solid #e3002f;
  color: #e3002f;
}
.placeLeft p:first-child span:nth-child(2).schoolActive {
  border: 0.01rem solid #68b460;
  color: #68b460;
}
.placeTag .defaultIcon {
  background-color: #383838;
  color: #fff;
}
.placeTag .defaultIcon i {
  width: 0.14rem;
  height: 0.14rem;
  background: url("../../assets/icon/defau.png") no-repeat center;
  background-size: cover;
}
.placeTag {
  height: 0.18rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.1rem !important;
  line-height: 0.18rem !important;
  margin-bottom: 0.11rem;
}
.placeTag p {
  width: auto !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 0.06rem !important;
  border-radius: 0.02rem;
  margin-right: 0.05rem;
}
.placeTag p span {
  margin: 0 !important;
  line-height: 0.18rem;
}
.placeTag p i {
  margin-right: 0.04rem;
}
.placeTag p.home {
  background-color: #F5E9EB;
}
.placeTag p.home i {
  width: 0.14rem;
  height: 0.14rem;
  background: url("../../assets/icon/wq.png") no-repeat center;
  background-size: 0.14rem 0.14rem;
}
.placeTag p.company {
  background-color: #F0BFC9;
}
.placeTag p.company i {
  width: 0.14rem;
  height: 0.12rem;
  background: url("../../assets/icon/com.png") no-repeat center;
  background-size: 0.14rem 0.12rem;
}
.placeTag p.other {
  background-color: #E5A0B0;
}
.placeTag p.other i {
  width: 0.14rem;
  height: 0.14rem;
  background: url("../../assets/icon/qt.png") no-repeat center;
  background-size: 0.14rem 0.14rem;
}
</style>