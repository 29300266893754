<template>
  <div class="place" id="place" @click="closePlace">
    <div class="placeList" :class="{ active: bottom }">
      <p class="title">{{ nowLocationP || nowLocation }}</p>
      <p class="nowPlace" @click="selectPlaceMap">
        <span>{{ place }}</span>
        <span></span>
      </p>
      <i class="line"></i>
      <!-- <p class="myPlace_">{{ chooseAddress }}</p> -->
      <place-item
        v-for="(item, index) in placeList"
        :key="item.addressCode"
        :placeInfo="item"
        :edit="false"
        @click="selectPlace(index)"
      ></place-item>
      <div class="addBtn" @click="addPlace">
        {{ $store.state.language == 1 ? "確認" : "Confirm" }}
      </div>
    </div>
  </div>
</template>
<script>
import PlaceItem from "./PlaceItem.vue";
import language from "../../assets/js/language.js";
import { postPaas } from "../../assets/utils/request";

export default {
  name: "Myplace",
  data() {
    return {
      placeList: [], //我的地址
      bottom: false, //
      placeInfo: null,
      // 静态文字
      nowLocation: "",
      chooseAddress: "",
      addNewAddress: "",
    };
  },
  props: {
    shopInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    nowLocationP: {
      type: String,
      default: "",
    },
    MS: {
      type: Boolean,
      default: false,
    },
    appInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    closePlace(e) {
      if (e.target.id == "place") {
        this.$emit("update", false);
      }
    },
    addPlace() {
      this.$emit("closeMyPlace");
      // this.$router.push("/sendPlace");
    },
    // 选择地址
    selectPlace(index) {
      this.$emit("select", this.placeList[index]);
    },
    //
    selectPlaceMap() {
      var query = {
        page: 1,
      };
      if (this.shopInfo.storeId) {
        query.shopInfo = JSON.stringify(this.shopInfo);
      }
      if (JSON.stringify(this.appInfo) != "{}") {
        query.appInfo = JSON.stringify(this.appInfo);
      }
      this.$router.push({
        path: "/selectShop",
        query: query,
      });
    },
    // 获取我的配送地址
    async getMyPlace() {
      var params = {
        actionName: "candao.member.addressGet",
        content: {},
      };
      if (this.$store.state.language == 1) {
        params.content.language = "TC";
      }
      if (this.$store.state.userInfo) {
        let placeList = await postPaas("UserUnify", params);
        if (placeList.status == 1 && placeList.data.data) {
          this.placeList = placeList.data.data;
          if (this.MS) {
            for (var i = 0; i < this.placeList.length; i++) {
              if (
                this.$store.state.placeInfo &&
                this.$store.state.placeInfo.addressCode
              ) {
                if (
                  this.$store.state.placeInfo.addressCode ==
                  this.placeList[i].addressCode
                ) {
                  this.selectPlace(i);
                  break;
                }
              }
            }
          }
        }
      }
    },
    //获取标签
    async getTag() {
      // var params = {
      //   actionName: "candao.member.miscAddressTag",
      //   content: {},
      // };
      // let placeTag = await postPaas("UserAzure", params);
    },
    // 切换语言
    setLanguage() {
      var languageType;
      if (this.$store.state.language == 1) {
        languageType = "chinese";
      } else {
        languageType = "english";
      }
      this.nowLocation = language.nowLocation[languageType];
      this.chooseAddress = language.chooseAddress[languageType];
      this.addNewAddress = language.addNewAddress[languageType];
    },
  },
  updated() {
    this.setLanguage();
  },
  created() {
    this.getMyPlace();
    this.getTag();
    this.setLanguage();
    var router = this.$route;
    if (router.name == "Home") {
      this.bottom = true;
    }
  },
  computed: {
    place() {
      var place = "";
      if (this.placeInfo) {
        place = this.placeInfo.building;
      } else if (this.$store.state.placeInfo) {
        place = this.$store.state.placeInfo.building;
      } else {
        place =
          this.$store.state.language == 1
            ? "無法定位"
            : "Location Not Available";
      }
      return place;
    },
  },
  components: {
    PlaceItem,
  },
};
</script>
<style scoped>
.place {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999999999999999;
}
.placeList {
  width: 100%;
  max-height: 4rem;
  background-color: #fff;
  border-radius: 0.12rem 0.12rem 0 0;
  padding: 0.16rem;
  box-sizing: border-box;
  position: fixed;
  bottom: 0rem;
  left: 0;
  overflow: scroll;
}
.placeList.active {
  bottom: 0.57rem;
}
.placeList::-webkit-scrollbar {
  display: none;
}
.title {
  height: 0.22rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.22rem;
  color: #e4022b;
  text-align: left;
}
.nowPlace {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.12rem 0 0.16rem;
}
.nowPlace span {
  width: 3.3rem;
  height: 0.22rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.22rem;
  color: #030f14;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.nowPlace span:last-child {
  width: 0.2rem;
  height: 0.2rem;
  background: url("../../assets/icon/locaPlace.png") no-repeat center;
  background-size: cover;
}
.line {
  display: block;
  width: 100%;
  height: 1px;
  background-color: #cacbcc;
}
.myPlace_ {
  height: 0.2rem;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.2rem;
  color: #474747;
  text-align: left;
  margin: 0.16rem 0 0.18rem;
}
.addBtn {
  width: 100%;
  height: 0.44rem;
  background-color: #e4022b;
  color: #fff;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.44rem;
  color: #ffffff;
  border-radius: 0.08rem;
  margin-top: 0.2rem;
}
</style>