<template>
  <div class="advert" id="advert" @click="closeAdvert">
    <div class="advertBox">
      <img @click="linkUrl" :src="advert ? advert.picUrl : ''" alt="" />
      <p class="closeIcon" @click="closeIconAdvert"></p>
    </div>
    <div class="num">{{ num }}</div>
  </div>
</template>
<script>
export default {
  name: "Advert",
  data() {
    return {
      num: 10,
      timer: null,
    };
  },
  props: {
    advert: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    linkUrl() {
      if (!this.advert.jumpUrl) return;
      window.location.href = this.advert.jumpUrl;
    },
    closeIconAdvert() {
      this.$emit("closeAdvert");
      clearInterval(this.timer);
    },
    closeAdvert(e) {
      if (e.target.id != "advert") return;
      this.$emit("closeAdvert");
      clearInterval(this.timer);
    },
    setTimer() {
      this.timer = null;
      this.timer = setInterval(() => {
        this.num--;
        if (this.num == 0) {
          clearInterval(this.timer);
          this.timer = null;
          this.closeIconAdvert();
        }
      }, 1000);
    },
  },
  mounted() {
    this.setTimer();
  },
};
</script>
<style scoped>
.advert {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
}
.advertBox {
  width: 3rem;
  height: 4.5rem;
  margin: 0.7rem auto 0;
  position: relative;
}
.closeIcon {
  display: block;
  width: 0.16rem;
  height: 0.16rem;
  border: 0.01rem solid #fff;
  color: #fff;
  position: absolute;
  top: 0.16rem;
  right: 0.16rem;
  border-radius: 50%;
  overflow: hidden;
  background: url("../../assets/icon/guanbi.png") no-repeat center;
  background-size: 0.14rem 0.14rem;
}
.advertBox img {
  width: 100%;
  object-fit: cover;
}
.num {
  width: 0.4rem;
  height: 0.25rem;
  color: black;
  font-size: 0.12rem;
  background: rgba(255, 255, 255, 0.3);
  margin: 0.24rem auto 0;
  line-height: 0.25rem;
  padding: 0 0.04rem;
  border-radius: 0.04rem;
}
</style>